<script setup lang="ts">
import { I18nT } from "vue-i18n";
import { useStorage } from "@vueuse/core";
import { Button } from "@/components/Ui";

const cookieAccept = useStorage("cookieAccept", 0);
const visible = computed(() => cookieAccept.value === 0);

function onClick(accept: boolean): void {
  cookieAccept.value = accept ? 1 : -1;
}
</script>

<template>
  <div
    v-if="visible"
    class="cookie_accept w-full fixed bottom-0 left-0 z-[100] py-6 px-6 md:px-16 border-t border-bg-level-2 flex flex-col gap-5 justify-between bg-white items-center md:flex-row"
  >
    <div class="flex flex-col gap-5 items-center md:flex-row">
      <img
        class="size-[68px]"
        src="@/assets/img/cookie.png"
      />
      <div class="flex flex-col gap-2">
        <div class="text-[24px] leading-7 font-[550]">
          {{ $t("cookie.notification.title") }}
        </div>
        <I18nT
          keypath="cookie.notification.message"
          tag="div"
          class="text-[18px] leading-6"
        >
          <a
            href="/legal/cookie"
            class="link"
            target="_blank"
            >{{ $t("cookie.notification.cookie-policy") }}
          </a>
        </I18nT>
      </div>
    </div>
    <div class="flex flex-col gap-2 w-full md:w-[210px]">
      <Button
        class="w-full md:min-w-[190px] h-[44px] rounded-6"
        shape="square"
        size="custom"
        rounded="custom"
        :title="$t('cookie.notification.accept-all-cookies')"
        @click="onClick(true)"
      />
      <Button
        class="w-full md:min-w-[210px]"
        color="white"
        shape="square"
        size="custom"
        @click="onClick(false)"
      >
        <span class="text-fg-secondary font-[550]">
          {{ $t("cookie.notification.decline-optional-cookies") }}
        </span>
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
.link {
  @apply underline text-fg-blue;
}
</style>
