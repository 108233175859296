<script setup lang="ts">
// import { ScreenLoader } from "@/components/Ui";
import { AppHeader, AppFooter } from "@/components/Layout";
import { AuthBanner } from "@/components/Auth";
import CookiesNotification from "@/components/CookiesNotification/CookiesNotification.vue";
import UnavailableCountries from "@/components/UnavailableCountries.vue";
import { onMounted, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import Amplitude from "@/helpers/amplitude";
import { isMobile } from "@/helpers/breakpoints";
import { getQueryData } from "@/helpers/query";
import { onEvent } from "@/helpers/events";
import { useLayoutStore } from "@/store/layout";
import config from "@/config/env";
import { useDictionaryStore } from "@/store/dictionary";

const route = useRoute();
const router = useRouter();
const i18n = useI18n();
const { localeCodes } = useI18n();
const layoutStore = useLayoutStore();
const isReady = ref<boolean>(false);

const dictionaryStore = useDictionaryStore();

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: undefined,
  addSeoAttributes: {},
});

const localeDirection = computed<"ltr" | "rtl">(() => {
  return i18nHead.value.htmlAttrs.dir || "ltr";
});

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs.lang!,
    class: "default",
    style: "",
  },
  bodyAttrs: {
    style: "",
  },
  link: [
    ...(i18nHead.value.link.filter(
      (item) => item.hreflang && item.hreflang.includes("-"),
    ) || []),
    { rel: "canonical", href: () => `${config.appUrl}${route.path}` },
  ],
  meta: [...(i18nHead.value.meta || [])],
});
const contentEl = ref<HTMLElement | null>(null);
const contentPos = useElementBounding(contentEl);

//methods

const promocodeTrafficReq = async () => {
  try {
    const params = new URLSearchParams(window?.location?.search || "");
    const body = {
      code: window?.localStorage.getItem("hash"),
      utm_source: params.get("utm_source") || "",
      utm_medium: params.get("utm_medium") || "",
      utm_campaign: params.get("utm_campaign") || "",
      utm_content: params.get("utm_content") || "",
      utm_term: params.get("utm_term") || "",
    };
    await $fetch(`/api/v1/promocode/traffic-count`, {
      method: "POST",
      body,
    });
  } catch (x) {
    console.error(x);
  }
};
// A/B Testing
onBeforeMount(() => {
  document.documentElement.setAttribute("dir", localeDirection.value);
  const landingExperiment =
    window.landingExperiment || route.query["landing-version"];
  if (landingExperiment !== undefined && landingExperiment !== 0) {
    layoutStore.landingVersion = String(landingExperiment);
  }
});
// A/B Testing
onMounted(() => {
  if (route.path !== "/") {
    const lang = route.query.lang;
    if (lang && localeCodes.value && localeCodes.value.includes(String(lang))) {
      i18n.setLocale(String(lang));
    }
  }

  const params = new URLSearchParams(window?.location?.search || "");
  const hash = params.get("hash");
  if (hash) {
    const validHash = hash.replace(/[^a-z0-9]/gi, "");
    window.localStorage.setItem("hash", validHash);
    promocodeTrafficReq();
  }
  const utmSource = params.get("utm_source");

  if (hash) {
    Amplitude.Instance.setUserProperties({
      source: "referral_user",
      referral_user_hash: hash,
    });
  } else if (utmSource) {
    Amplitude.Instance.setUserProperties({
      source: utmSource,
    });
  } else {
    Amplitude.Instance.setUserProperties({
      source: document?.referrer || "",
    });
  }

  // customer_io
  if (utmSource === "c_io") {
    onEvent(
      "customer_io",
      {},
      {
        utm_source: params.get("utm_source") || "",
        utm_medium: params.get("utm_medium") || "",
        utm_campaign: params.get("utm_campaign") || "",
        utm_content: params.get("utm_content") || "",
        utm_term: params.get("utm_term") || "",
      },
    );
  }
  Amplitude.setUserPropertyFromQuery(route);
  getQueryData(route, router);

  // A/B Testing

  if (layoutStore.landingVersion && i18n.locale) {
    Amplitude.Instance.setUserProperties({
      landing: layoutStore.landingVersion,
      landing_locale: i18n.locale.value,
    });
  }

  const root = document.documentElement;
  root.classList.add(`lv-${layoutStore.landingVersion}`);
  // A/B Testing
});

watch(localeDirection, () => {
  document.documentElement.setAttribute("dir", localeDirection.value);
});

const isShowUnavailableCountries = computed(() => {
  const route = useRoute();
  return isReady.value && route.path !== "/private-landing";
});

onNuxtReady(() => {
  isReady.value = true;
  if (!dictionaryStore.isReady && !dictionaryStore.pending) {
    dictionaryStore.fetchDictionary();
  }
});

useSeoMeta({
  ogImage: config.appUrl + "/og-image.png",
  ogType: "website",
});
</script>

<template>
  <div>
    <!--  Banners-->
    <AuthBanner
      v-if="isMobile"
      :class="layout.auth"
    />
    <UnavailableCountries v-if="isShowUnavailableCountries" />
    <!--  Header-->
    <AppHeader
      :fixed="contentPos.top.value !== 0"
      :class="[layout.header, layout[`header-${contentPos.top.value < 0}`]]"
    />
    <!--  Main-->
    <div
      ref="contentEl"
      :class="layout.content"
    >
      <div :class="layout.slot">
        <slot />
      </div>

      <!--    Footer-->
      <AppFooter />
    </div>
    <CookiesNotification />
  </div>
</template>
<style lang="scss" module="layout">
.content {
  @apply flex flex-col min-h-screen;
}

.slot {
  @apply flex-1;
}

.auth {
  @apply hidden md:block;
}

// Header
.header {
  @apply fixed w-full flex items-center transition-all transform-gpu;
  @apply z-[1200];
  &-false {
    @apply h-[70px] md:h-[90px];
  }

  &-true {
    @apply h-[56px] md:h-[70px] bg-white backdrop-blur-md bg-opacity-[0.85];
    @apply dark:bg-neutral-900 dark:bg-opacity-90;
  }
}
</style>
