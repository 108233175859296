import validate from "/home/server/builds/landing-2025-01-23-18-52/node_modules/nuxt/dist/pages/runtime/validate.js";
import route_45global from "/home/server/builds/landing-2025-01-23-18-52/middleware/route.global.ts";
import utm_45global from "/home/server/builds/landing-2025-01-23-18-52/middleware/utm.global.ts";
export const globalMiddleware = [
  validate,
  route_45global,
  utm_45global
]
export const namedMiddleware = {
  error: () => import("/home/server/builds/landing-2025-01-23-18-52/middleware/error.ts"),
  landing: () => import("/home/server/builds/landing-2025-01-23-18-52/middleware/landing.ts")
}