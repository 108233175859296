import { default as affiliatewYYmKmvNe6Meta } from "/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue?macro=true";
import { default as _91slug_93b9VT4KTdrOMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue?macro=true";
import { default as advRDUTt9qgjWMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue?macro=true";
import { default as fbvH4QvY9Dz9Meta } from "/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue?macro=true";
import { default as indexGIwB1U6fBeMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue?macro=true";
import { default as platinumo59o9fGuA3Meta } from "/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue?macro=true";
import { default as ultimasFucMwCsAmMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue?macro=true";
import { default as extensionm3tOu40ERTMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue?macro=true";
import { default as _91slug_93DK9XsziR7OMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue?macro=true";
import { default as indexyXn5qeEseTMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue?macro=true";
import { default as faqYR8TDkTkPBMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue?macro=true";
import { default as indexWKIN8N8vp5Meta } from "/home/server/builds/landing-2025-01-23-18-52/pages/index.vue?macro=true";
import { default as _91slug_93pTvkgLr2TtMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue?macro=true";
import { default as indexAmRKhuCNTpMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue?macro=true";
import { default as legalNfXtXQQkDqMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue?macro=true";
import { default as pst_45ultimanMAAc9yrfgMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue?macro=true";
import { default as mobile_45app5jhR89kyUUMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue?macro=true";
import { default as _91slug_93vR2oOA8qDLMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue?macro=true";
import { default as private_45landingBC0hKX2S33Meta } from "/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue?macro=true";
import { default as private4fhAsM6ThxMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/private.vue?macro=true";
import { default as promoDU1lLs0rYYMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue?macro=true";
import { default as pstRLCuMcwfvvMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue?macro=true";
import { default as _91slug_9388Y3tQm0buMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue?macro=true";
import { default as indexZEHWEkWeJZMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue?macro=true";
import { default as whitelabelbhDygBQcLyMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue?macro=true";
import { default as worldwidemIm0ZcIvGuMeta } from "/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue?macro=true";
export default [
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___ru",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/ru/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___en",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___es",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/es/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___fr",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/fr/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___de",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/de/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___it",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/it/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___pt",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/pt/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___zh",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/zh/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___bg",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/bg/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___tr",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/tr/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___ja",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/ja/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___ua",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/ua/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___th",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/th/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___hi",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/hi/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___be",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/be/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___pl",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/pl/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___ar",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/ar/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___bn",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/bn/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___ko",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/ko/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___sw",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/sw/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___vi",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/vi/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___id",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/id/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___da",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/da/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___et",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/et/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___hu",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/hu/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___lt",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/lt/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___nb",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/nb/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___ro",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/ro/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___sl",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/sl/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___cs",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/cs/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___el",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/el/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___fi",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/fi/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___lv",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/lv/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___nl",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/nl/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___sk",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/sk/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___sv",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/sv/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: affiliatewYYmKmvNe6Meta?.name ?? "affiliate___he",
    path: affiliatewYYmKmvNe6Meta?.path ?? "/he/affiliate",
    meta: affiliatewYYmKmvNe6Meta || {},
    alias: affiliatewYYmKmvNe6Meta?.alias || [],
    redirect: affiliatewYYmKmvNe6Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___ru",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/ru/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___en",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___es",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/es/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___fr",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/fr/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___de",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/de/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___it",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/it/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___pt",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/pt/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___zh",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/zh/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___bg",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/bg/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___tr",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/tr/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___ja",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/ja/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___ua",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/ua/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___th",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/th/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___hi",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/hi/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___be",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/be/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___pl",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/pl/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___ar",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/ar/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___bn",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/bn/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___ko",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/ko/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___sw",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/sw/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___vi",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/vi/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___id",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/id/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___da",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/da/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___et",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/et/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___hu",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/hu/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___lt",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/lt/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___nb",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/nb/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___ro",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/ro/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___sl",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/sl/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___cs",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/cs/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___el",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/el/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___fi",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/fi/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___lv",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/lv/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___nl",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/nl/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___sk",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/sk/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___sv",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/sv/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b9VT4KTdrOMeta?.name ?? "cards-slug___he",
    path: _91slug_93b9VT4KTdrOMeta?.path ?? "/he/cards/:slug()",
    meta: _91slug_93b9VT4KTdrOMeta || {},
    alias: _91slug_93b9VT4KTdrOMeta?.alias || [],
    redirect: _91slug_93b9VT4KTdrOMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___ru",
    path: advRDUTt9qgjWMeta?.path ?? "/ru/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___en",
    path: advRDUTt9qgjWMeta?.path ?? "/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___es",
    path: advRDUTt9qgjWMeta?.path ?? "/es/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___fr",
    path: advRDUTt9qgjWMeta?.path ?? "/fr/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___de",
    path: advRDUTt9qgjWMeta?.path ?? "/de/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___it",
    path: advRDUTt9qgjWMeta?.path ?? "/it/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___pt",
    path: advRDUTt9qgjWMeta?.path ?? "/pt/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___zh",
    path: advRDUTt9qgjWMeta?.path ?? "/zh/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___bg",
    path: advRDUTt9qgjWMeta?.path ?? "/bg/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___tr",
    path: advRDUTt9qgjWMeta?.path ?? "/tr/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___ja",
    path: advRDUTt9qgjWMeta?.path ?? "/ja/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___ua",
    path: advRDUTt9qgjWMeta?.path ?? "/ua/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___th",
    path: advRDUTt9qgjWMeta?.path ?? "/th/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___hi",
    path: advRDUTt9qgjWMeta?.path ?? "/hi/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___be",
    path: advRDUTt9qgjWMeta?.path ?? "/be/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___pl",
    path: advRDUTt9qgjWMeta?.path ?? "/pl/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___ar",
    path: advRDUTt9qgjWMeta?.path ?? "/ar/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___bn",
    path: advRDUTt9qgjWMeta?.path ?? "/bn/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___ko",
    path: advRDUTt9qgjWMeta?.path ?? "/ko/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___sw",
    path: advRDUTt9qgjWMeta?.path ?? "/sw/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___vi",
    path: advRDUTt9qgjWMeta?.path ?? "/vi/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___id",
    path: advRDUTt9qgjWMeta?.path ?? "/id/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___da",
    path: advRDUTt9qgjWMeta?.path ?? "/da/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___et",
    path: advRDUTt9qgjWMeta?.path ?? "/et/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___hu",
    path: advRDUTt9qgjWMeta?.path ?? "/hu/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___lt",
    path: advRDUTt9qgjWMeta?.path ?? "/lt/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___nb",
    path: advRDUTt9qgjWMeta?.path ?? "/nb/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___ro",
    path: advRDUTt9qgjWMeta?.path ?? "/ro/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___sl",
    path: advRDUTt9qgjWMeta?.path ?? "/sl/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___cs",
    path: advRDUTt9qgjWMeta?.path ?? "/cs/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___el",
    path: advRDUTt9qgjWMeta?.path ?? "/el/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___fi",
    path: advRDUTt9qgjWMeta?.path ?? "/fi/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___lv",
    path: advRDUTt9qgjWMeta?.path ?? "/lv/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___nl",
    path: advRDUTt9qgjWMeta?.path ?? "/nl/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___sk",
    path: advRDUTt9qgjWMeta?.path ?? "/sk/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___sv",
    path: advRDUTt9qgjWMeta?.path ?? "/sv/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: advRDUTt9qgjWMeta?.name ?? "cards-adv___he",
    path: advRDUTt9qgjWMeta?.path ?? "/he/cards/adv",
    meta: advRDUTt9qgjWMeta || {},
    alias: advRDUTt9qgjWMeta?.alias || [],
    redirect: advRDUTt9qgjWMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/adv.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___ru",
    path: fbvH4QvY9Dz9Meta?.path ?? "/ru/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___en",
    path: fbvH4QvY9Dz9Meta?.path ?? "/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___es",
    path: fbvH4QvY9Dz9Meta?.path ?? "/es/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___fr",
    path: fbvH4QvY9Dz9Meta?.path ?? "/fr/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___de",
    path: fbvH4QvY9Dz9Meta?.path ?? "/de/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___it",
    path: fbvH4QvY9Dz9Meta?.path ?? "/it/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___pt",
    path: fbvH4QvY9Dz9Meta?.path ?? "/pt/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___zh",
    path: fbvH4QvY9Dz9Meta?.path ?? "/zh/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___bg",
    path: fbvH4QvY9Dz9Meta?.path ?? "/bg/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___tr",
    path: fbvH4QvY9Dz9Meta?.path ?? "/tr/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___ja",
    path: fbvH4QvY9Dz9Meta?.path ?? "/ja/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___ua",
    path: fbvH4QvY9Dz9Meta?.path ?? "/ua/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___th",
    path: fbvH4QvY9Dz9Meta?.path ?? "/th/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___hi",
    path: fbvH4QvY9Dz9Meta?.path ?? "/hi/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___be",
    path: fbvH4QvY9Dz9Meta?.path ?? "/be/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___pl",
    path: fbvH4QvY9Dz9Meta?.path ?? "/pl/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___ar",
    path: fbvH4QvY9Dz9Meta?.path ?? "/ar/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___bn",
    path: fbvH4QvY9Dz9Meta?.path ?? "/bn/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___ko",
    path: fbvH4QvY9Dz9Meta?.path ?? "/ko/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___sw",
    path: fbvH4QvY9Dz9Meta?.path ?? "/sw/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___vi",
    path: fbvH4QvY9Dz9Meta?.path ?? "/vi/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___id",
    path: fbvH4QvY9Dz9Meta?.path ?? "/id/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___da",
    path: fbvH4QvY9Dz9Meta?.path ?? "/da/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___et",
    path: fbvH4QvY9Dz9Meta?.path ?? "/et/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___hu",
    path: fbvH4QvY9Dz9Meta?.path ?? "/hu/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___lt",
    path: fbvH4QvY9Dz9Meta?.path ?? "/lt/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___nb",
    path: fbvH4QvY9Dz9Meta?.path ?? "/nb/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___ro",
    path: fbvH4QvY9Dz9Meta?.path ?? "/ro/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___sl",
    path: fbvH4QvY9Dz9Meta?.path ?? "/sl/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___cs",
    path: fbvH4QvY9Dz9Meta?.path ?? "/cs/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___el",
    path: fbvH4QvY9Dz9Meta?.path ?? "/el/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___fi",
    path: fbvH4QvY9Dz9Meta?.path ?? "/fi/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___lv",
    path: fbvH4QvY9Dz9Meta?.path ?? "/lv/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___nl",
    path: fbvH4QvY9Dz9Meta?.path ?? "/nl/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___sk",
    path: fbvH4QvY9Dz9Meta?.path ?? "/sk/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___sv",
    path: fbvH4QvY9Dz9Meta?.path ?? "/sv/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: fbvH4QvY9Dz9Meta?.name ?? "cards-fb___he",
    path: fbvH4QvY9Dz9Meta?.path ?? "/he/cards/fb",
    meta: fbvH4QvY9Dz9Meta || {},
    alias: fbvH4QvY9Dz9Meta?.alias || [],
    redirect: fbvH4QvY9Dz9Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/fb.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___ru",
    path: indexGIwB1U6fBeMeta?.path ?? "/ru/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___en",
    path: indexGIwB1U6fBeMeta?.path ?? "/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___es",
    path: indexGIwB1U6fBeMeta?.path ?? "/es/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___fr",
    path: indexGIwB1U6fBeMeta?.path ?? "/fr/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___de",
    path: indexGIwB1U6fBeMeta?.path ?? "/de/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___it",
    path: indexGIwB1U6fBeMeta?.path ?? "/it/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___pt",
    path: indexGIwB1U6fBeMeta?.path ?? "/pt/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___zh",
    path: indexGIwB1U6fBeMeta?.path ?? "/zh/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___bg",
    path: indexGIwB1U6fBeMeta?.path ?? "/bg/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___tr",
    path: indexGIwB1U6fBeMeta?.path ?? "/tr/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___ja",
    path: indexGIwB1U6fBeMeta?.path ?? "/ja/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___ua",
    path: indexGIwB1U6fBeMeta?.path ?? "/ua/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___th",
    path: indexGIwB1U6fBeMeta?.path ?? "/th/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___hi",
    path: indexGIwB1U6fBeMeta?.path ?? "/hi/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___be",
    path: indexGIwB1U6fBeMeta?.path ?? "/be/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___pl",
    path: indexGIwB1U6fBeMeta?.path ?? "/pl/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___ar",
    path: indexGIwB1U6fBeMeta?.path ?? "/ar/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___bn",
    path: indexGIwB1U6fBeMeta?.path ?? "/bn/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___ko",
    path: indexGIwB1U6fBeMeta?.path ?? "/ko/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___sw",
    path: indexGIwB1U6fBeMeta?.path ?? "/sw/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___vi",
    path: indexGIwB1U6fBeMeta?.path ?? "/vi/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___id",
    path: indexGIwB1U6fBeMeta?.path ?? "/id/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___da",
    path: indexGIwB1U6fBeMeta?.path ?? "/da/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___et",
    path: indexGIwB1U6fBeMeta?.path ?? "/et/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___hu",
    path: indexGIwB1U6fBeMeta?.path ?? "/hu/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___lt",
    path: indexGIwB1U6fBeMeta?.path ?? "/lt/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___nb",
    path: indexGIwB1U6fBeMeta?.path ?? "/nb/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___ro",
    path: indexGIwB1U6fBeMeta?.path ?? "/ro/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___sl",
    path: indexGIwB1U6fBeMeta?.path ?? "/sl/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___cs",
    path: indexGIwB1U6fBeMeta?.path ?? "/cs/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___el",
    path: indexGIwB1U6fBeMeta?.path ?? "/el/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___fi",
    path: indexGIwB1U6fBeMeta?.path ?? "/fi/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___lv",
    path: indexGIwB1U6fBeMeta?.path ?? "/lv/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___nl",
    path: indexGIwB1U6fBeMeta?.path ?? "/nl/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___sk",
    path: indexGIwB1U6fBeMeta?.path ?? "/sk/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___sv",
    path: indexGIwB1U6fBeMeta?.path ?? "/sv/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIwB1U6fBeMeta?.name ?? "cards___he",
    path: indexGIwB1U6fBeMeta?.path ?? "/he/cards",
    meta: indexGIwB1U6fBeMeta || {},
    alias: indexGIwB1U6fBeMeta?.alias || [],
    redirect: indexGIwB1U6fBeMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___ru",
    path: platinumo59o9fGuA3Meta?.path ?? "/ru/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___en",
    path: platinumo59o9fGuA3Meta?.path ?? "/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___es",
    path: platinumo59o9fGuA3Meta?.path ?? "/es/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___fr",
    path: platinumo59o9fGuA3Meta?.path ?? "/fr/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___de",
    path: platinumo59o9fGuA3Meta?.path ?? "/de/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___it",
    path: platinumo59o9fGuA3Meta?.path ?? "/it/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___pt",
    path: platinumo59o9fGuA3Meta?.path ?? "/pt/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___zh",
    path: platinumo59o9fGuA3Meta?.path ?? "/zh/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___bg",
    path: platinumo59o9fGuA3Meta?.path ?? "/bg/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___tr",
    path: platinumo59o9fGuA3Meta?.path ?? "/tr/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___ja",
    path: platinumo59o9fGuA3Meta?.path ?? "/ja/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___ua",
    path: platinumo59o9fGuA3Meta?.path ?? "/ua/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___th",
    path: platinumo59o9fGuA3Meta?.path ?? "/th/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___hi",
    path: platinumo59o9fGuA3Meta?.path ?? "/hi/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___be",
    path: platinumo59o9fGuA3Meta?.path ?? "/be/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___pl",
    path: platinumo59o9fGuA3Meta?.path ?? "/pl/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___ar",
    path: platinumo59o9fGuA3Meta?.path ?? "/ar/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___bn",
    path: platinumo59o9fGuA3Meta?.path ?? "/bn/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___ko",
    path: platinumo59o9fGuA3Meta?.path ?? "/ko/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___sw",
    path: platinumo59o9fGuA3Meta?.path ?? "/sw/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___vi",
    path: platinumo59o9fGuA3Meta?.path ?? "/vi/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___id",
    path: platinumo59o9fGuA3Meta?.path ?? "/id/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___da",
    path: platinumo59o9fGuA3Meta?.path ?? "/da/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___et",
    path: platinumo59o9fGuA3Meta?.path ?? "/et/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___hu",
    path: platinumo59o9fGuA3Meta?.path ?? "/hu/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___lt",
    path: platinumo59o9fGuA3Meta?.path ?? "/lt/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___nb",
    path: platinumo59o9fGuA3Meta?.path ?? "/nb/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___ro",
    path: platinumo59o9fGuA3Meta?.path ?? "/ro/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___sl",
    path: platinumo59o9fGuA3Meta?.path ?? "/sl/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___cs",
    path: platinumo59o9fGuA3Meta?.path ?? "/cs/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___el",
    path: platinumo59o9fGuA3Meta?.path ?? "/el/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___fi",
    path: platinumo59o9fGuA3Meta?.path ?? "/fi/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___lv",
    path: platinumo59o9fGuA3Meta?.path ?? "/lv/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___nl",
    path: platinumo59o9fGuA3Meta?.path ?? "/nl/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___sk",
    path: platinumo59o9fGuA3Meta?.path ?? "/sk/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___sv",
    path: platinumo59o9fGuA3Meta?.path ?? "/sv/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: platinumo59o9fGuA3Meta?.name ?? "cards-platinum___he",
    path: platinumo59o9fGuA3Meta?.path ?? "/he/cards/platinum",
    meta: platinumo59o9fGuA3Meta || {},
    alias: platinumo59o9fGuA3Meta?.alias || [],
    redirect: platinumo59o9fGuA3Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/platinum.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___ru",
    path: ultimasFucMwCsAmMeta?.path ?? "/ru/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___en",
    path: ultimasFucMwCsAmMeta?.path ?? "/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___es",
    path: ultimasFucMwCsAmMeta?.path ?? "/es/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___fr",
    path: ultimasFucMwCsAmMeta?.path ?? "/fr/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___de",
    path: ultimasFucMwCsAmMeta?.path ?? "/de/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___it",
    path: ultimasFucMwCsAmMeta?.path ?? "/it/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___pt",
    path: ultimasFucMwCsAmMeta?.path ?? "/pt/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___zh",
    path: ultimasFucMwCsAmMeta?.path ?? "/zh/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___bg",
    path: ultimasFucMwCsAmMeta?.path ?? "/bg/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___tr",
    path: ultimasFucMwCsAmMeta?.path ?? "/tr/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___ja",
    path: ultimasFucMwCsAmMeta?.path ?? "/ja/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___ua",
    path: ultimasFucMwCsAmMeta?.path ?? "/ua/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___th",
    path: ultimasFucMwCsAmMeta?.path ?? "/th/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___hi",
    path: ultimasFucMwCsAmMeta?.path ?? "/hi/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___be",
    path: ultimasFucMwCsAmMeta?.path ?? "/be/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___pl",
    path: ultimasFucMwCsAmMeta?.path ?? "/pl/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___ar",
    path: ultimasFucMwCsAmMeta?.path ?? "/ar/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___bn",
    path: ultimasFucMwCsAmMeta?.path ?? "/bn/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___ko",
    path: ultimasFucMwCsAmMeta?.path ?? "/ko/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___sw",
    path: ultimasFucMwCsAmMeta?.path ?? "/sw/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___vi",
    path: ultimasFucMwCsAmMeta?.path ?? "/vi/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___id",
    path: ultimasFucMwCsAmMeta?.path ?? "/id/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___da",
    path: ultimasFucMwCsAmMeta?.path ?? "/da/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___et",
    path: ultimasFucMwCsAmMeta?.path ?? "/et/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___hu",
    path: ultimasFucMwCsAmMeta?.path ?? "/hu/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___lt",
    path: ultimasFucMwCsAmMeta?.path ?? "/lt/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___nb",
    path: ultimasFucMwCsAmMeta?.path ?? "/nb/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___ro",
    path: ultimasFucMwCsAmMeta?.path ?? "/ro/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___sl",
    path: ultimasFucMwCsAmMeta?.path ?? "/sl/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___cs",
    path: ultimasFucMwCsAmMeta?.path ?? "/cs/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___el",
    path: ultimasFucMwCsAmMeta?.path ?? "/el/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___fi",
    path: ultimasFucMwCsAmMeta?.path ?? "/fi/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___lv",
    path: ultimasFucMwCsAmMeta?.path ?? "/lv/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___nl",
    path: ultimasFucMwCsAmMeta?.path ?? "/nl/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___sk",
    path: ultimasFucMwCsAmMeta?.path ?? "/sk/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___sv",
    path: ultimasFucMwCsAmMeta?.path ?? "/sv/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: ultimasFucMwCsAmMeta?.name ?? "cards-ultima___he",
    path: ultimasFucMwCsAmMeta?.path ?? "/he/cards/ultima",
    meta: ultimasFucMwCsAmMeta || {},
    alias: ultimasFucMwCsAmMeta?.alias || [],
    redirect: ultimasFucMwCsAmMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/cards/ultima.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___ru",
    path: extensionm3tOu40ERTMeta?.path ?? "/ru/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___en",
    path: extensionm3tOu40ERTMeta?.path ?? "/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___es",
    path: extensionm3tOu40ERTMeta?.path ?? "/es/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___fr",
    path: extensionm3tOu40ERTMeta?.path ?? "/fr/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___de",
    path: extensionm3tOu40ERTMeta?.path ?? "/de/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___it",
    path: extensionm3tOu40ERTMeta?.path ?? "/it/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___pt",
    path: extensionm3tOu40ERTMeta?.path ?? "/pt/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___zh",
    path: extensionm3tOu40ERTMeta?.path ?? "/zh/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___bg",
    path: extensionm3tOu40ERTMeta?.path ?? "/bg/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___tr",
    path: extensionm3tOu40ERTMeta?.path ?? "/tr/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___ja",
    path: extensionm3tOu40ERTMeta?.path ?? "/ja/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___ua",
    path: extensionm3tOu40ERTMeta?.path ?? "/ua/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___th",
    path: extensionm3tOu40ERTMeta?.path ?? "/th/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___hi",
    path: extensionm3tOu40ERTMeta?.path ?? "/hi/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___be",
    path: extensionm3tOu40ERTMeta?.path ?? "/be/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___pl",
    path: extensionm3tOu40ERTMeta?.path ?? "/pl/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___ar",
    path: extensionm3tOu40ERTMeta?.path ?? "/ar/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___bn",
    path: extensionm3tOu40ERTMeta?.path ?? "/bn/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___ko",
    path: extensionm3tOu40ERTMeta?.path ?? "/ko/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___sw",
    path: extensionm3tOu40ERTMeta?.path ?? "/sw/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___vi",
    path: extensionm3tOu40ERTMeta?.path ?? "/vi/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___id",
    path: extensionm3tOu40ERTMeta?.path ?? "/id/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___da",
    path: extensionm3tOu40ERTMeta?.path ?? "/da/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___et",
    path: extensionm3tOu40ERTMeta?.path ?? "/et/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___hu",
    path: extensionm3tOu40ERTMeta?.path ?? "/hu/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___lt",
    path: extensionm3tOu40ERTMeta?.path ?? "/lt/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___nb",
    path: extensionm3tOu40ERTMeta?.path ?? "/nb/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___ro",
    path: extensionm3tOu40ERTMeta?.path ?? "/ro/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___sl",
    path: extensionm3tOu40ERTMeta?.path ?? "/sl/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___cs",
    path: extensionm3tOu40ERTMeta?.path ?? "/cs/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___el",
    path: extensionm3tOu40ERTMeta?.path ?? "/el/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___fi",
    path: extensionm3tOu40ERTMeta?.path ?? "/fi/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___lv",
    path: extensionm3tOu40ERTMeta?.path ?? "/lv/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___nl",
    path: extensionm3tOu40ERTMeta?.path ?? "/nl/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___sk",
    path: extensionm3tOu40ERTMeta?.path ?? "/sk/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___sv",
    path: extensionm3tOu40ERTMeta?.path ?? "/sv/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    name: extensionm3tOu40ERTMeta?.name ?? "extension___he",
    path: extensionm3tOu40ERTMeta?.path ?? "/he/extension",
    meta: extensionm3tOu40ERTMeta || {},
    alias: extensionm3tOu40ERTMeta?.alias || [],
    redirect: extensionm3tOu40ERTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/extension.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/ru/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___ru",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___ru",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___en",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___en",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/es/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___es",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___es",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/fr/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___fr",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___fr",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/de/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___de",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___de",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/it/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___it",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___it",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/pt/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___pt",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___pt",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/zh/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___zh",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___zh",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/bg/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___bg",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___bg",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/tr/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___tr",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___tr",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/ja/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___ja",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___ja",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/ua/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___ua",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___ua",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/th/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___th",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___th",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/hi/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___hi",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___hi",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/be/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___be",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___be",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/pl/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___pl",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___pl",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/ar/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___ar",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___ar",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/bn/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___bn",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___bn",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/ko/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___ko",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___ko",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/sw/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___sw",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___sw",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/vi/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___vi",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___vi",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/id/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___id",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___id",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/da/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___da",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___da",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/et/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___et",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___et",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/hu/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___hu",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___hu",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/lt/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___lt",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___lt",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/nb/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___nb",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___nb",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/ro/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___ro",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___ro",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/sl/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___sl",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___sl",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/cs/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___cs",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___cs",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/el/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___el",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___el",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/fi/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___fi",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___fi",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/lv/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___lv",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___lv",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/nl/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___nl",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___nl",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/sk/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___sk",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___sk",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/sv/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___sv",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___sv",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    path: faqYR8TDkTkPBMeta?.path ?? "/he/faq",
    children: [
  {
    name: _91slug_93DK9XsziR7OMeta?.name ?? "faq-slug___he",
    path: _91slug_93DK9XsziR7OMeta?.path ?? ":slug()",
    meta: _91slug_93DK9XsziR7OMeta || {},
    alias: _91slug_93DK9XsziR7OMeta?.alias || [],
    redirect: _91slug_93DK9XsziR7OMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyXn5qeEseTMeta?.name ?? "faq___he",
    path: indexyXn5qeEseTMeta?.path ?? "",
    meta: indexyXn5qeEseTMeta || {},
    alias: indexyXn5qeEseTMeta?.alias || [],
    redirect: indexyXn5qeEseTMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq/index.vue").then(m => m.default || m)
  }
],
    name: faqYR8TDkTkPBMeta?.name ?? undefined,
    meta: faqYR8TDkTkPBMeta || {},
    alias: faqYR8TDkTkPBMeta?.alias || [],
    redirect: faqYR8TDkTkPBMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___ru",
    path: indexWKIN8N8vp5Meta?.path ?? "/ru",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___en",
    path: indexWKIN8N8vp5Meta?.path ?? "/",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___es",
    path: indexWKIN8N8vp5Meta?.path ?? "/es",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___fr",
    path: indexWKIN8N8vp5Meta?.path ?? "/fr",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___de",
    path: indexWKIN8N8vp5Meta?.path ?? "/de",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___it",
    path: indexWKIN8N8vp5Meta?.path ?? "/it",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___pt",
    path: indexWKIN8N8vp5Meta?.path ?? "/pt",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___zh",
    path: indexWKIN8N8vp5Meta?.path ?? "/zh",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___bg",
    path: indexWKIN8N8vp5Meta?.path ?? "/bg",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___tr",
    path: indexWKIN8N8vp5Meta?.path ?? "/tr",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___ja",
    path: indexWKIN8N8vp5Meta?.path ?? "/ja",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___ua",
    path: indexWKIN8N8vp5Meta?.path ?? "/ua",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___th",
    path: indexWKIN8N8vp5Meta?.path ?? "/th",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___hi",
    path: indexWKIN8N8vp5Meta?.path ?? "/hi",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___be",
    path: indexWKIN8N8vp5Meta?.path ?? "/be",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___pl",
    path: indexWKIN8N8vp5Meta?.path ?? "/pl",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___ar",
    path: indexWKIN8N8vp5Meta?.path ?? "/ar",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___bn",
    path: indexWKIN8N8vp5Meta?.path ?? "/bn",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___ko",
    path: indexWKIN8N8vp5Meta?.path ?? "/ko",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___sw",
    path: indexWKIN8N8vp5Meta?.path ?? "/sw",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___vi",
    path: indexWKIN8N8vp5Meta?.path ?? "/vi",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___id",
    path: indexWKIN8N8vp5Meta?.path ?? "/id",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___da",
    path: indexWKIN8N8vp5Meta?.path ?? "/da",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___et",
    path: indexWKIN8N8vp5Meta?.path ?? "/et",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___hu",
    path: indexWKIN8N8vp5Meta?.path ?? "/hu",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___lt",
    path: indexWKIN8N8vp5Meta?.path ?? "/lt",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___nb",
    path: indexWKIN8N8vp5Meta?.path ?? "/nb",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___ro",
    path: indexWKIN8N8vp5Meta?.path ?? "/ro",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___sl",
    path: indexWKIN8N8vp5Meta?.path ?? "/sl",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___cs",
    path: indexWKIN8N8vp5Meta?.path ?? "/cs",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___el",
    path: indexWKIN8N8vp5Meta?.path ?? "/el",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___fi",
    path: indexWKIN8N8vp5Meta?.path ?? "/fi",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___lv",
    path: indexWKIN8N8vp5Meta?.path ?? "/lv",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___nl",
    path: indexWKIN8N8vp5Meta?.path ?? "/nl",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___sk",
    path: indexWKIN8N8vp5Meta?.path ?? "/sk",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___sv",
    path: indexWKIN8N8vp5Meta?.path ?? "/sv",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKIN8N8vp5Meta?.name ?? "index___he",
    path: indexWKIN8N8vp5Meta?.path ?? "/he",
    meta: indexWKIN8N8vp5Meta || {},
    alias: indexWKIN8N8vp5Meta?.alias || [],
    redirect: indexWKIN8N8vp5Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/index.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/ru/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___ru",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___ru",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___en",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___en",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/es/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___es",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___es",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/fr/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___fr",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___fr",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/de/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___de",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___de",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/it/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___it",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___it",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/pt/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___pt",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___pt",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/zh/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___zh",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___zh",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/bg/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___bg",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___bg",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/tr/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___tr",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___tr",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/ja/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___ja",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___ja",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/ua/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___ua",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___ua",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/th/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___th",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___th",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/hi/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___hi",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___hi",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/be/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___be",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___be",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/pl/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___pl",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___pl",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/ar/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___ar",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___ar",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/bn/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___bn",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___bn",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/ko/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___ko",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___ko",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/sw/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___sw",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___sw",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/vi/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___vi",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___vi",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/id/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___id",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___id",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/da/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___da",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___da",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/et/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___et",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___et",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/hu/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___hu",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___hu",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/lt/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___lt",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___lt",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/nb/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___nb",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___nb",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/ro/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___ro",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___ro",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/sl/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___sl",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___sl",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/cs/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___cs",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___cs",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/el/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___el",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___el",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/fi/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___fi",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___fi",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/lv/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___lv",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___lv",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/nl/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___nl",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___nl",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/sk/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___sk",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___sk",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/sv/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___sv",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___sv",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    path: legalNfXtXQQkDqMeta?.path ?? "/he/legal",
    children: [
  {
    name: _91slug_93pTvkgLr2TtMeta?.name ?? "legal-slug___he",
    path: _91slug_93pTvkgLr2TtMeta?.path ?? ":slug()",
    meta: _91slug_93pTvkgLr2TtMeta || {},
    alias: _91slug_93pTvkgLr2TtMeta?.alias || [],
    redirect: _91slug_93pTvkgLr2TtMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAmRKhuCNTpMeta?.name ?? "legal___he",
    path: indexAmRKhuCNTpMeta?.path ?? "",
    meta: indexAmRKhuCNTpMeta || {},
    alias: indexAmRKhuCNTpMeta?.alias || [],
    redirect: indexAmRKhuCNTpMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal/index.vue").then(m => m.default || m)
  }
],
    name: legalNfXtXQQkDqMeta?.name ?? undefined,
    meta: legalNfXtXQQkDqMeta || {},
    alias: legalNfXtXQQkDqMeta?.alias || [],
    redirect: legalNfXtXQQkDqMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___ru",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/ru/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___en",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___es",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/es/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___fr",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/fr/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___de",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/de/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___it",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/it/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___pt",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/pt/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___zh",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/zh/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___bg",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/bg/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___tr",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/tr/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___ja",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/ja/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___ua",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/ua/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___th",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/th/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___hi",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/hi/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___be",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/be/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___pl",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/pl/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___ar",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/ar/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___bn",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/bn/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___ko",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/ko/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___sw",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/sw/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___vi",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/vi/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___id",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/id/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___da",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/da/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___et",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/et/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___hu",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/hu/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___lt",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/lt/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___nb",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/nb/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___ro",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/ro/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___sl",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/sl/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___cs",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/cs/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___el",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/el/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___fi",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/fi/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___lv",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/lv/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___nl",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/nl/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___sk",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/sk/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___sv",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/sv/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: pst_45ultimanMAAc9yrfgMeta?.name ?? "marketing-landings-pst-ultima___he",
    path: pst_45ultimanMAAc9yrfgMeta?.path ?? "/he/marketing/landings/pst-ultima",
    meta: pst_45ultimanMAAc9yrfgMeta || {},
    alias: pst_45ultimanMAAc9yrfgMeta?.alias || [],
    redirect: pst_45ultimanMAAc9yrfgMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/marketing/landings/pst-ultima.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___ru",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/ru/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___en",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___es",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/es/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___fr",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/fr/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___de",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/de/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___it",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/it/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___pt",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/pt/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___zh",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/zh/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___bg",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/bg/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___tr",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/tr/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___ja",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/ja/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___ua",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/ua/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___th",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/th/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___hi",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/hi/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___be",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/be/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___pl",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/pl/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___ar",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/ar/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___bn",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/bn/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___ko",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/ko/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___sw",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/sw/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___vi",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/vi/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___id",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/id/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___da",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/da/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___et",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/et/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___hu",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/hu/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___lt",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/lt/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___nb",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/nb/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___ro",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/ro/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___sl",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/sl/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___cs",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/cs/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___el",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/el/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___fi",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/fi/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___lv",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/lv/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___nl",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/nl/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___sk",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/sk/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___sv",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/sv/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app5jhR89kyUUMeta?.name ?? "mobile-app___he",
    path: mobile_45app5jhR89kyUUMeta?.path ?? "/he/mobile-app",
    meta: mobile_45app5jhR89kyUUMeta || {},
    alias: mobile_45app5jhR89kyUUMeta?.alias || [],
    redirect: mobile_45app5jhR89kyUUMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___ru",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/ru/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___en",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___es",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/es/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___fr",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/fr/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___de",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/de/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___it",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/it/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___pt",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/pt/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___zh",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/zh/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___bg",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/bg/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___tr",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/tr/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___ja",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/ja/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___ua",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/ua/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___th",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/th/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___hi",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/hi/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___be",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/be/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___pl",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/pl/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___ar",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/ar/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___bn",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/bn/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___ko",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/ko/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___sw",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/sw/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___vi",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/vi/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___id",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/id/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___da",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/da/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___et",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/et/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___hu",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/hu/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___lt",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/lt/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___nb",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/nb/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___ro",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/ro/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___sl",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/sl/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___cs",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/cs/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___el",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/el/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___fi",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/fi/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___lv",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/lv/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___nl",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/nl/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___sk",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/sk/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___sv",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/sv/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vR2oOA8qDLMeta?.name ?? "payment-slug___he",
    path: _91slug_93vR2oOA8qDLMeta?.path ?? "/he/payment/:slug()",
    meta: _91slug_93vR2oOA8qDLMeta || {},
    alias: _91slug_93vR2oOA8qDLMeta?.alias || [],
    redirect: _91slug_93vR2oOA8qDLMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___ru",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/ru/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___en",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___es",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/es/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___fr",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/fr/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___de",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/de/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___it",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/it/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___pt",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/pt/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___zh",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/zh/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___bg",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/bg/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___tr",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/tr/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___ja",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/ja/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___ua",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/ua/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___th",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/th/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___hi",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/hi/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___be",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/be/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___pl",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/pl/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___ar",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/ar/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___bn",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/bn/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___ko",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/ko/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___sw",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/sw/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___vi",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/vi/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___id",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/id/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___da",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/da/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___et",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/et/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___hu",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/hu/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___lt",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/lt/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___nb",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/nb/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___ro",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/ro/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___sl",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/sl/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___cs",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/cs/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___el",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/el/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___fi",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/fi/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___lv",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/lv/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___nl",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/nl/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___sk",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/sk/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___sv",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/sv/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private_45landingBC0hKX2S33Meta?.name ?? "private-landing___he",
    path: private_45landingBC0hKX2S33Meta?.path ?? "/he/private-landing",
    meta: private_45landingBC0hKX2S33Meta || {},
    alias: private_45landingBC0hKX2S33Meta?.alias || [],
    redirect: private_45landingBC0hKX2S33Meta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private-landing.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___ru",
    path: private4fhAsM6ThxMeta?.path ?? "/ru/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___en",
    path: private4fhAsM6ThxMeta?.path ?? "/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___es",
    path: private4fhAsM6ThxMeta?.path ?? "/es/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___fr",
    path: private4fhAsM6ThxMeta?.path ?? "/fr/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___de",
    path: private4fhAsM6ThxMeta?.path ?? "/de/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___it",
    path: private4fhAsM6ThxMeta?.path ?? "/it/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___pt",
    path: private4fhAsM6ThxMeta?.path ?? "/pt/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___zh",
    path: private4fhAsM6ThxMeta?.path ?? "/zh/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___bg",
    path: private4fhAsM6ThxMeta?.path ?? "/bg/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___tr",
    path: private4fhAsM6ThxMeta?.path ?? "/tr/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___ja",
    path: private4fhAsM6ThxMeta?.path ?? "/ja/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___ua",
    path: private4fhAsM6ThxMeta?.path ?? "/ua/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___th",
    path: private4fhAsM6ThxMeta?.path ?? "/th/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___hi",
    path: private4fhAsM6ThxMeta?.path ?? "/hi/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___be",
    path: private4fhAsM6ThxMeta?.path ?? "/be/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___pl",
    path: private4fhAsM6ThxMeta?.path ?? "/pl/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___ar",
    path: private4fhAsM6ThxMeta?.path ?? "/ar/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___bn",
    path: private4fhAsM6ThxMeta?.path ?? "/bn/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___ko",
    path: private4fhAsM6ThxMeta?.path ?? "/ko/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___sw",
    path: private4fhAsM6ThxMeta?.path ?? "/sw/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___vi",
    path: private4fhAsM6ThxMeta?.path ?? "/vi/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___id",
    path: private4fhAsM6ThxMeta?.path ?? "/id/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___da",
    path: private4fhAsM6ThxMeta?.path ?? "/da/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___et",
    path: private4fhAsM6ThxMeta?.path ?? "/et/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___hu",
    path: private4fhAsM6ThxMeta?.path ?? "/hu/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___lt",
    path: private4fhAsM6ThxMeta?.path ?? "/lt/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___nb",
    path: private4fhAsM6ThxMeta?.path ?? "/nb/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___ro",
    path: private4fhAsM6ThxMeta?.path ?? "/ro/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___sl",
    path: private4fhAsM6ThxMeta?.path ?? "/sl/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___cs",
    path: private4fhAsM6ThxMeta?.path ?? "/cs/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___el",
    path: private4fhAsM6ThxMeta?.path ?? "/el/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___fi",
    path: private4fhAsM6ThxMeta?.path ?? "/fi/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___lv",
    path: private4fhAsM6ThxMeta?.path ?? "/lv/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___nl",
    path: private4fhAsM6ThxMeta?.path ?? "/nl/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___sk",
    path: private4fhAsM6ThxMeta?.path ?? "/sk/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___sv",
    path: private4fhAsM6ThxMeta?.path ?? "/sv/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: private4fhAsM6ThxMeta?.name ?? "private___he",
    path: private4fhAsM6ThxMeta?.path ?? "/he/private",
    meta: private4fhAsM6ThxMeta || {},
    alias: private4fhAsM6ThxMeta?.alias || [],
    redirect: private4fhAsM6ThxMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/private.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___ru",
    path: promoDU1lLs0rYYMeta?.path ?? "/ru/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___en",
    path: promoDU1lLs0rYYMeta?.path ?? "/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___es",
    path: promoDU1lLs0rYYMeta?.path ?? "/es/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___fr",
    path: promoDU1lLs0rYYMeta?.path ?? "/fr/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___de",
    path: promoDU1lLs0rYYMeta?.path ?? "/de/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___it",
    path: promoDU1lLs0rYYMeta?.path ?? "/it/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___pt",
    path: promoDU1lLs0rYYMeta?.path ?? "/pt/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___zh",
    path: promoDU1lLs0rYYMeta?.path ?? "/zh/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___bg",
    path: promoDU1lLs0rYYMeta?.path ?? "/bg/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___tr",
    path: promoDU1lLs0rYYMeta?.path ?? "/tr/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___ja",
    path: promoDU1lLs0rYYMeta?.path ?? "/ja/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___ua",
    path: promoDU1lLs0rYYMeta?.path ?? "/ua/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___th",
    path: promoDU1lLs0rYYMeta?.path ?? "/th/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___hi",
    path: promoDU1lLs0rYYMeta?.path ?? "/hi/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___be",
    path: promoDU1lLs0rYYMeta?.path ?? "/be/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___pl",
    path: promoDU1lLs0rYYMeta?.path ?? "/pl/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___ar",
    path: promoDU1lLs0rYYMeta?.path ?? "/ar/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___bn",
    path: promoDU1lLs0rYYMeta?.path ?? "/bn/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___ko",
    path: promoDU1lLs0rYYMeta?.path ?? "/ko/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___sw",
    path: promoDU1lLs0rYYMeta?.path ?? "/sw/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___vi",
    path: promoDU1lLs0rYYMeta?.path ?? "/vi/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___id",
    path: promoDU1lLs0rYYMeta?.path ?? "/id/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___da",
    path: promoDU1lLs0rYYMeta?.path ?? "/da/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___et",
    path: promoDU1lLs0rYYMeta?.path ?? "/et/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___hu",
    path: promoDU1lLs0rYYMeta?.path ?? "/hu/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___lt",
    path: promoDU1lLs0rYYMeta?.path ?? "/lt/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___nb",
    path: promoDU1lLs0rYYMeta?.path ?? "/nb/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___ro",
    path: promoDU1lLs0rYYMeta?.path ?? "/ro/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___sl",
    path: promoDU1lLs0rYYMeta?.path ?? "/sl/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___cs",
    path: promoDU1lLs0rYYMeta?.path ?? "/cs/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___el",
    path: promoDU1lLs0rYYMeta?.path ?? "/el/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___fi",
    path: promoDU1lLs0rYYMeta?.path ?? "/fi/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___lv",
    path: promoDU1lLs0rYYMeta?.path ?? "/lv/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___nl",
    path: promoDU1lLs0rYYMeta?.path ?? "/nl/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___sk",
    path: promoDU1lLs0rYYMeta?.path ?? "/sk/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___sv",
    path: promoDU1lLs0rYYMeta?.path ?? "/sv/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: promoDU1lLs0rYYMeta?.name ?? "promo___he",
    path: promoDU1lLs0rYYMeta?.path ?? "/he/promo",
    meta: promoDU1lLs0rYYMeta || {},
    alias: promoDU1lLs0rYYMeta?.alias || [],
    redirect: promoDU1lLs0rYYMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/promo.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___ru",
    path: pstRLCuMcwfvvMeta?.path ?? "/ru/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___en",
    path: pstRLCuMcwfvvMeta?.path ?? "/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___es",
    path: pstRLCuMcwfvvMeta?.path ?? "/es/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___fr",
    path: pstRLCuMcwfvvMeta?.path ?? "/fr/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___de",
    path: pstRLCuMcwfvvMeta?.path ?? "/de/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___it",
    path: pstRLCuMcwfvvMeta?.path ?? "/it/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___pt",
    path: pstRLCuMcwfvvMeta?.path ?? "/pt/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___zh",
    path: pstRLCuMcwfvvMeta?.path ?? "/zh/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___bg",
    path: pstRLCuMcwfvvMeta?.path ?? "/bg/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___tr",
    path: pstRLCuMcwfvvMeta?.path ?? "/tr/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___ja",
    path: pstRLCuMcwfvvMeta?.path ?? "/ja/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___ua",
    path: pstRLCuMcwfvvMeta?.path ?? "/ua/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___th",
    path: pstRLCuMcwfvvMeta?.path ?? "/th/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___hi",
    path: pstRLCuMcwfvvMeta?.path ?? "/hi/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___be",
    path: pstRLCuMcwfvvMeta?.path ?? "/be/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___pl",
    path: pstRLCuMcwfvvMeta?.path ?? "/pl/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___ar",
    path: pstRLCuMcwfvvMeta?.path ?? "/ar/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___bn",
    path: pstRLCuMcwfvvMeta?.path ?? "/bn/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___ko",
    path: pstRLCuMcwfvvMeta?.path ?? "/ko/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___sw",
    path: pstRLCuMcwfvvMeta?.path ?? "/sw/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___vi",
    path: pstRLCuMcwfvvMeta?.path ?? "/vi/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___id",
    path: pstRLCuMcwfvvMeta?.path ?? "/id/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___da",
    path: pstRLCuMcwfvvMeta?.path ?? "/da/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___et",
    path: pstRLCuMcwfvvMeta?.path ?? "/et/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___hu",
    path: pstRLCuMcwfvvMeta?.path ?? "/hu/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___lt",
    path: pstRLCuMcwfvvMeta?.path ?? "/lt/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___nb",
    path: pstRLCuMcwfvvMeta?.path ?? "/nb/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___ro",
    path: pstRLCuMcwfvvMeta?.path ?? "/ro/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___sl",
    path: pstRLCuMcwfvvMeta?.path ?? "/sl/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___cs",
    path: pstRLCuMcwfvvMeta?.path ?? "/cs/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___el",
    path: pstRLCuMcwfvvMeta?.path ?? "/el/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___fi",
    path: pstRLCuMcwfvvMeta?.path ?? "/fi/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___lv",
    path: pstRLCuMcwfvvMeta?.path ?? "/lv/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___nl",
    path: pstRLCuMcwfvvMeta?.path ?? "/nl/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___sk",
    path: pstRLCuMcwfvvMeta?.path ?? "/sk/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___sv",
    path: pstRLCuMcwfvvMeta?.path ?? "/sv/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: pstRLCuMcwfvvMeta?.name ?? "pst___he",
    path: pstRLCuMcwfvvMeta?.path ?? "/he/pst",
    meta: pstRLCuMcwfvvMeta || {},
    alias: pstRLCuMcwfvvMeta?.alias || [],
    redirect: pstRLCuMcwfvvMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/pst.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___ru",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/ru/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___en",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___es",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/es/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___fr",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/fr/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___de",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/de/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___it",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/it/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___pt",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/pt/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___zh",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/zh/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___bg",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/bg/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___tr",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/tr/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___ja",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/ja/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___ua",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/ua/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___th",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/th/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___hi",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/hi/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___be",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/be/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___pl",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/pl/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___ar",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/ar/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___bn",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/bn/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___ko",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/ko/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___sw",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/sw/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___vi",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/vi/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___id",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/id/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___da",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/da/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___et",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/et/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___hu",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/hu/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___lt",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/lt/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___nb",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/nb/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___ro",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/ro/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___sl",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/sl/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___cs",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/cs/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___el",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/el/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___fi",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/fi/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___lv",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/lv/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___nl",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/nl/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___sk",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/sk/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___sv",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/sv/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9388Y3tQm0buMeta?.name ?? "reviews-slug___he",
    path: _91slug_9388Y3tQm0buMeta?.path ?? "/he/reviews/:slug()",
    meta: _91slug_9388Y3tQm0buMeta || {},
    alias: _91slug_9388Y3tQm0buMeta?.alias || [],
    redirect: _91slug_9388Y3tQm0buMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___ru",
    path: indexZEHWEkWeJZMeta?.path ?? "/ru/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___en",
    path: indexZEHWEkWeJZMeta?.path ?? "/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___es",
    path: indexZEHWEkWeJZMeta?.path ?? "/es/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___fr",
    path: indexZEHWEkWeJZMeta?.path ?? "/fr/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___de",
    path: indexZEHWEkWeJZMeta?.path ?? "/de/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___it",
    path: indexZEHWEkWeJZMeta?.path ?? "/it/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___pt",
    path: indexZEHWEkWeJZMeta?.path ?? "/pt/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___zh",
    path: indexZEHWEkWeJZMeta?.path ?? "/zh/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___bg",
    path: indexZEHWEkWeJZMeta?.path ?? "/bg/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___tr",
    path: indexZEHWEkWeJZMeta?.path ?? "/tr/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___ja",
    path: indexZEHWEkWeJZMeta?.path ?? "/ja/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___ua",
    path: indexZEHWEkWeJZMeta?.path ?? "/ua/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___th",
    path: indexZEHWEkWeJZMeta?.path ?? "/th/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___hi",
    path: indexZEHWEkWeJZMeta?.path ?? "/hi/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___be",
    path: indexZEHWEkWeJZMeta?.path ?? "/be/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___pl",
    path: indexZEHWEkWeJZMeta?.path ?? "/pl/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___ar",
    path: indexZEHWEkWeJZMeta?.path ?? "/ar/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___bn",
    path: indexZEHWEkWeJZMeta?.path ?? "/bn/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___ko",
    path: indexZEHWEkWeJZMeta?.path ?? "/ko/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___sw",
    path: indexZEHWEkWeJZMeta?.path ?? "/sw/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___vi",
    path: indexZEHWEkWeJZMeta?.path ?? "/vi/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___id",
    path: indexZEHWEkWeJZMeta?.path ?? "/id/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___da",
    path: indexZEHWEkWeJZMeta?.path ?? "/da/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___et",
    path: indexZEHWEkWeJZMeta?.path ?? "/et/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___hu",
    path: indexZEHWEkWeJZMeta?.path ?? "/hu/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___lt",
    path: indexZEHWEkWeJZMeta?.path ?? "/lt/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___nb",
    path: indexZEHWEkWeJZMeta?.path ?? "/nb/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___ro",
    path: indexZEHWEkWeJZMeta?.path ?? "/ro/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___sl",
    path: indexZEHWEkWeJZMeta?.path ?? "/sl/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___cs",
    path: indexZEHWEkWeJZMeta?.path ?? "/cs/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___el",
    path: indexZEHWEkWeJZMeta?.path ?? "/el/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___fi",
    path: indexZEHWEkWeJZMeta?.path ?? "/fi/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___lv",
    path: indexZEHWEkWeJZMeta?.path ?? "/lv/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___nl",
    path: indexZEHWEkWeJZMeta?.path ?? "/nl/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___sk",
    path: indexZEHWEkWeJZMeta?.path ?? "/sk/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___sv",
    path: indexZEHWEkWeJZMeta?.path ?? "/sv/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexZEHWEkWeJZMeta?.name ?? "reviews___he",
    path: indexZEHWEkWeJZMeta?.path ?? "/he/reviews",
    meta: indexZEHWEkWeJZMeta || {},
    alias: indexZEHWEkWeJZMeta?.alias || [],
    redirect: indexZEHWEkWeJZMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___ru",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/ru/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___en",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___es",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/es/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___fr",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/fr/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___de",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/de/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___it",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/it/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___pt",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/pt/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___zh",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/zh/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___bg",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/bg/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___tr",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/tr/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___ja",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/ja/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___ua",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/ua/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___th",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/th/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___hi",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/hi/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___be",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/be/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___pl",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/pl/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___ar",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/ar/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___bn",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/bn/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___ko",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/ko/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___sw",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/sw/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___vi",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/vi/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___id",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/id/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___da",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/da/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___et",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/et/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___hu",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/hu/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___lt",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/lt/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___nb",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/nb/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___ro",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/ro/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___sl",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/sl/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___cs",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/cs/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___el",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/el/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___fi",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/fi/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___lv",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/lv/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___nl",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/nl/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___sk",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/sk/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___sv",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/sv/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: whitelabelbhDygBQcLyMeta?.name ?? "whitelabel___he",
    path: whitelabelbhDygBQcLyMeta?.path ?? "/he/whitelabel",
    meta: whitelabelbhDygBQcLyMeta || {},
    alias: whitelabelbhDygBQcLyMeta?.alias || [],
    redirect: whitelabelbhDygBQcLyMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/whitelabel.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___ru",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/ru/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___en",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___es",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/es/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___fr",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/fr/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___de",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/de/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___it",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/it/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___pt",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/pt/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___zh",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/zh/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___bg",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/bg/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___tr",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/tr/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___ja",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/ja/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___ua",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/ua/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___th",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/th/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___hi",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/hi/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___be",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/be/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___pl",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/pl/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___ar",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/ar/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___bn",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/bn/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___ko",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/ko/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___sw",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/sw/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___vi",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/vi/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___id",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/id/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___da",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/da/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___et",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/et/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___hu",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/hu/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___lt",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/lt/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___nb",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/nb/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___ro",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/ro/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___sl",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/sl/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___cs",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/cs/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___el",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/el/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___fi",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/fi/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___lv",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/lv/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___nl",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/nl/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___sk",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/sk/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___sv",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/sv/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  },
  {
    name: worldwidemIm0ZcIvGuMeta?.name ?? "worldwide___he",
    path: worldwidemIm0ZcIvGuMeta?.path ?? "/he/worldwide",
    meta: worldwidemIm0ZcIvGuMeta || {},
    alias: worldwidemIm0ZcIvGuMeta?.alias || [],
    redirect: worldwidemIm0ZcIvGuMeta?.redirect || undefined,
    component: () => import("/home/server/builds/landing-2025-01-23-18-52/pages/worldwide.vue").then(m => m.default || m)
  }
]